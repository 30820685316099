<template>
  <div class="Awarp">
    <dashCard
      class="lvscBox11"
      :operateWidth="operateWidth"
      :isLoading="isLoading"
      :gridOption="gridOption"
      @changeScreen="changeScreen"
    >
      <template slot="title">抽屉电量参数</template>
      <template slot="aside">
        <div class="type-group">
          <span
            class="pick-type"
            v-for="(item, idx) in timetType"
            :key="idx"
            @click="changeType(item.code)"
          >
            <font :class="{ 'isactive-type': item.code == searchObj.TYPE }">{{
              item.name
            }}</font>
          </span>
        </div>
        <a-date-picker
          v-if="searchObj.TYPE == '按天'"
          :placeholder="commonLangHandler('chuneng7_day','选天', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-week-picker
          v-if="searchObj.TYPE == '按周'"
          :placeholder="commonLangHandler('powerBox28_selectWeek','选周', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-month-picker
          v-if="searchObj.TYPE == '按月'"
          :placeholder="commonLangHandler('chuneng7_month','选月', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-date-picker
          v-if="searchObj.TYPE == '按年'"
          :placeholder="commonLangHandler('chuneng7_year','选年', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
          mode="year"
          :open="panelOpen"
          @openChange="openChange"
          @panelChange="panelChange"
          format="YYYY"
        />
      </template>
      <div class="card-content1">
        <div class="card-content-table">
          <div class="card-content-table-item">
            <span>A相电流</span>
            <span>506.5A</span>
          </div>
          <div class="card-content-table-item">
            <span>B相电流</span>
            <span>618.0A</span>
          </div>
          <div class="card-content-table-item">
            <span>C相电流</span>
            <span>413.1A</span>
          </div>
        </div>
        <div class="card-content-box">
          <chartBoard ref="chart1" :option="chartOption1" />
        </div>
      </div>
      <div class="content-space"></div>
      <div class="card-content2">
        <div class="card-content-table">
          <div class="card-content-table-item">
            <span>线电压Uab</span>
            <span>321.5V</span>
          </div>
          <div class="card-content-table-item">
            <span>线电压Ubc</span>
            <span>329.0V</span>
          </div>
          <div class="card-content-table-item">
            <span>线电压Uac</span>
            <span>321.2V</span>
          </div>
        </div>
        <div class="card-content-box">
          <chartBoard ref="chart2" :option="chartOption2" />
        </div>
      </div>
    </dashCard>
    <a-modal
      v-if="!isModal"
      class="Amodal"
      v-model="option.visible"
      :closable="false"
      :footer="null"
      :keyboard="false"
      width="100%"
      :dialog-style="{ top: '0px' }"
    >
      <div style="height: calc(100vh)">
        <lvscBox11
          :option="option"
          :isModal="true"
          :orginSearchObj="searchObj"
        />
      </div>
    </a-modal>
  </div>
</template>
    <script>
export default {
  name: "lvscBox11",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom1 = this.$refs["chart1"];
          if (!!dom1) {
            dom1.handleWindowResize();
          }
          var dom2 = this.$refs["chart2"];
          if (!!dom2) {
            dom2.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中

      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
       
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      panelOpen: false,
      searchObj: {
        TYPE: "按天", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
        ITEM: [], //选中的项目
      },
      chartOption1: {},
      chartOption2: {},
      detailInfo: {
        chart1: {},
        chart2: {},
      },
      colorList: ["#3366FF", "#1E6119"],
    };
  },
  computed: {},
  methods: {
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },

    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type;
      this.getData();
    },
    openChange(status) {
      this.panelOpen = status;
    },
    // 年份弹窗关闭
    panelChange(value) {
      this.searchObj.VALUE = value;
      this.panelOpen = false;
      this.getData();
    },
    onChange(value) {
      console.log("onChange", value, this.searchObj);
      this.getData();
    },
    //每月报警预警数
    getData() {
      this.isLoading = true;
      this.detailInfo = {};
      var chart1 = {
        categoryData: ["lbA", "lbB", "lbC"],
        seriesData: [
          {
            name: "电流",
            value: [500, 610, 400],
          },
        ],
      };
      var chart2 = {
        categoryData: ["Uab", "Ubc", "Uac"],
        seriesData: [
          {
            name: "电压",
            value: [321.5, 329.0, 312.2],
          },
        ],
      };
      this.detailInfo.chart1 = chart1;
      this.detailInfo.chart2 = chart2;
      this.initEchart();
      this.isLoading = false;
    },
    initEchart() {
      this.$nextTick((_) => {
        this.initEchart1();
        this.initEchart2();
      });
    },
    initEchart1() {
      var { categoryData, seriesData } = this.detailInfo.chart1;

      var series = [];
      seriesData.forEach((item) => {
        var obj = {
          type: "bar",
          animationDurationUpdate: 2000,
          animationEasingUpdate: "quinticInOut",
          name: item.name,
          data: item.value,
          smooth: false,
          showSymbol: false,
          barGap: 0,
          barWidth: 10,
          emphasis: {
            focus: "series",
          },
        };
        series.push(obj);
      });
      var option = {
        animation: true,
        layoutAnimation: false,
        grid: {
          left: "5%",
          right: "5%",
          bottom: "0%",
          top: "0%",
          containLabel: true,
        },
        // legend: {
        //     right: 0,
        //     top: '0%',
        //     orient: "vertical",
        //     icon: "rect", //图例形状
        //     itemWidth: 8,
        //     itemHeight: 8,
        //     textStyle: {
        //         fontFamily: 'ABBvoice_WCNSG_Rg',
        //         color: '#1d2129'
        //     },
        // },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        xAxis: {
          type: "value",
          // name: "单位(t)",
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#9f9f9f",
            fontSize: 14,
          },
          axisLine: {
            lineStyle: {
              color: "#1f1f1f",
            },
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
          },
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              color: "#bababa",
            },
          },
          // 控制数据参数最大值和最小值
          // interval: 50,
          // max: 100
        },
        yAxis: {
          type: "category",
          data: categoryData,
          boundaryGap: true,
          axisLine: {
            lineStyle: {
              color: "#1f1f1f",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
          },
          splitLine: {
            show: false,
            lineStyle: {
              // type: "dashed",
              width: 0.5,
              color: "#bababa",
            },
          },
        },
        // 控住柱状图样式
        series: series,
        color: this.colorList,
      };

      this.updateChart("chart1", "chartOption1", option);
    },
    initEchart2() {
      var { categoryData, seriesData } = this.detailInfo.chart2;

      var series = [];
      seriesData.forEach((item) => {
        var obj = {
          type: "bar",
          animationDurationUpdate: 2000,
          animationEasingUpdate: "quinticInOut",
          name: item.name,
          data: item.value,
          smooth: false,
          showSymbol: false,
          barGap: 0,
          barWidth: 10,
        };
        series.push(obj);
      });
      var option = {
        animation: true,
        layoutAnimation: false,
        grid: {
          left: "5%",
          right: "5%",
          bottom: "0%",
          top: "0%",
          containLabel: true,
        },
        // legend: {
        //     right: 0,
        //     top: '0%',
        //     orient: "vertical",
        //     icon: "rect", //图例形状
        //     itemWidth: 8,
        //     itemHeight: 8,
        //     textStyle: {
        //         fontFamily: 'ABBvoice_WCNSG_Rg',
        //         color: '#1d2129'
        //     },
        // },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        xAxis: {
          type: "value",
          // name: "单位(t)",
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#9f9f9f",
            fontSize: 14,
          },
          axisLine: {
            lineStyle: {
              color: "#1f1f1f",
            },
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
          },
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              color: "#bababa",
            },
          },
          // 控制数据参数最大值和最小值
          // interval: 50,
          // max: 100
        },
        yAxis: {
          type: "category",
          data: categoryData,
          boundaryGap: true,
          axisLine: {
            lineStyle: {
              color: "#1f1f1f",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
          },
          splitLine: {
            show: false,
            lineStyle: {
              // type: "dashed",
              width: 0.5,
              color: "#bababa",
            },
          },
        },
        // 控住柱状图样式
        series: series,
        color: this.colorList,
      };

      this.updateChart("chart2", "chartOption2", option);
    },
    updateChart: function (refName, optionName, option = {}) {
      /* 渲染echart图 */
      if (!optionName) return;
      this[optionName] = option;
      setTimeout(() => {
        this.$refs[refName].updateChartView();
      }, 300);
    },
  },
  mounted() {
    this.getOperateWidth();
    if (!this.option.visible) {
      this.getData();
    }
  },
};
</script>
    
<style lang="less" scoped>
.lvscBox11 {
  .content-space {
    height: 44px;
    width: inherit;
  }
  .card-content1,
  .card-content2 {
    flex: 1;
    height: 100%;
    display: flex;
  }
  .card-content-table {
    width: 296px;
    height: inherit;
    .card-content-table-item {
      display: flex;
      justify-content: space-between;
      height: 48px;
      width: 100%;
      box-sizing: border-box;
      background: #fafafa;

      font-size: 14px;
      font-weight: 400;
      color: #1f1f1f;
      line-height: 48px;
      span {
        margin-left: 8px;
        margin-right: 8px;
      }
      &:nth-child(2n) {
        background: transparent;
      }
    }
  }
  .card-content-box {
    flex: 1;
    height: inherit;
  }
}
</style>