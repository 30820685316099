import { render, staticRenderFns } from "./lvscBox11.vue?vue&type=template&id=26870732&scoped=true"
import script from "./lvscBox11.vue?vue&type=script&lang=js"
export * from "./lvscBox11.vue?vue&type=script&lang=js"
import style0 from "./lvscBox11.vue?vue&type=style&index=0&id=26870732&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26870732",
  null
  
)

export default component.exports